@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');

* {
  --black: #0d1317;
  --green: #659b5e;
  --yellow: #f2c55d;
  --gray: #5E565A;
  --light-gray: rgb(129, 131, 132);
  --red: #AF3800;
  --white: #ffffff;
  --background: var(--black);
  --foreground: var(--white);
}

html {
  width: 100%;
  height: 100%;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  background-color: var(--background);
  color: var(--white);

  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;

  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.Game {
  /* game does not take up whole screen */
  height: 100%;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Game>header {
  font-weight: 700;
  letter-spacing: 3.2px;
  text-align: center;
  border-bottom: 2px solid var(--white);
  font-size: 0.75em;
}

.Game>.alert {
  text-align: center;
}

.Game>.alert>h1,
.Game>.alert>h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.board {
  margin-top: 20px;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-gap: 5px;
  width: 75%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.GameRow {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
}

.GameRow[data-guessinvalid="true"] {
  color: var(--red);
}

.GameTile {
  display: inline-flex;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid var(--gray);

  text-transform: uppercase;

  justify-content: center;
  align-items: center;
  vertical-align: middle;


  font-weight: 700;
  font-size: 1.5em;
}

.GameTile[data-status="green"] {
  background-color: var(--green);
  border-color: var(--green);
}

.GameTile[data-status="yellow"] {
  background-color: var(--yellow);
  border-color: var(--yellow);
}

.GameTile[data-status="gray"] {
  background-color: var(--gray);
  border-color: var(--gray);
}

.GameTile::before {
  content: '';
  display: inline-block;
  padding-bottom: 100%;
}

.Keyboard {
  margin-top: 30px;
}

.KeyboardRow {
  display: flex;
  width: 100%;
  margin: 0 auto 8px auto;
  justify-content: space-between;
}

.keySpacer {
  display: block;
  flex: 0.5;
}

.Key {
  background-color: var(--light-gray);
  color: var(--foreground);
  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 3px 0 3px;
  height: 58px;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.Key[data-letterstatus="green"] {
  background-color: var(--green);
}

.Key[data-letterstatus="yellow"] {
  background-color: var(--yellow);
}

.Key[data-letterstatus="gray"] {
  background-color: var(--gray);
}

.Key.special {
  flex: 1.25;
}